import { Link } from "gatsby";
import React from "react";

const Footer = ({ siteTitle }) => (
  <footer className="bg-white">
    <div className="container mx-auto  px-8">

          <p className="uppercase text-gray-500 md:mb-6">Offshore Health Benefits, Ltd</p>
    </div>
  </footer>
);

export default Footer;
